/* eslint-disable @ngrx/avoid-mapping-selectors */
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { delay, filter, map, Observable } from 'rxjs';

import { TokenService } from './providers/services/token.service';
import { AuthActions } from './store/actions/auth.actions';
import { AuthenticationService } from './providers/services/authentication.service';
import { TokenDetails } from './models/auth/token-details.type';
import { concatLatestFrom } from '@ngrx/operators';
import { selectTokenDetails, selectUserTenants } from './store/selectors/auth.selectors';

import { Theme } from './models/core/theme.type';
import { selectLoading, selectTheme } from './store/selectors/system.selectors';
import { AppState } from './store/states/app.state';
import { LoadingSpinnerComponent } from './components/ui-elements/loading-spinner/loading-spinner.component';
import { NotificationHandlerDirective } from './directives/notification-handler.directive';
import { CubeTranslateDirective } from './directives/translate.directive';
import { CubeLayoutModeHandlerDirective } from './directives/layout-mode-handler.directive';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'cube-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoadingSpinnerComponent, NotificationHandlerDirective, CubeTranslateDirective, CubeLayoutModeHandlerDirective],
})
export class AppComponent {
  isDarkTheme = false;
  loading$: Observable<boolean>;

  private readonly authService: AuthenticationService = inject(AuthenticationService);
  private readonly tokenService: TokenService = inject(TokenService);

  constructor(private readonly store: Store<AppState>, private overlayContainer: OverlayContainer) {
    this.store.dispatch(AuthActions.login());

    this.authService.token.pipe(
      filter((token: string | undefined): token is string => !!token)).subscribe(token => {
        const uniqueId = this.tokenService.getUniqueId(token);
        this.store.dispatch(AuthActions.setTokenDetails({tokenDetails: {idToken: token, uniqueId} as TokenDetails}))})

    this.loading$ = this.store.select(selectLoading).pipe(delay(500));

    this.store
      .select<Theme>(selectTheme)
      .pipe(
        map((theme: Theme): boolean => theme === 'dark'),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;

          this.overlayContainer.getContainerElement().classList.remove(isDark ? 'light-theme' : 'dark-theme');
          this.overlayContainer.getContainerElement().classList.add(!isDark ? 'light-theme' : 'dark-theme');
        },
      });

      this.store
      .select(selectTokenDetails)
      .pipe(
        filter((tokenDetails: TokenDetails | undefined): tokenDetails is TokenDetails => !!tokenDetails),
        concatLatestFrom(() => this.store.select(selectUserTenants)),
        takeUntilDestroyed()
      )
      .subscribe({
        next: ([tokenDetails, tenants]) => {
          if (!tenants.length) {
            this.store.dispatch(AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId }));
          }
        },
      });
  }
}
