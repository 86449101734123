import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Tenant, ProfileRole, ProfileFeature } from 'src/app/models/auth/admin-tool.type';
import { TokenDetails } from 'src/app/models/auth/token-details.type';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    RedirectToGateway: emptyProps(),
    Login:  emptyProps(),
    LoginSuccess:props<{ tokenDetails: TokenDetails }>(),
    Logout: emptyProps(),
    SetActiveAccount: emptyProps(),
    SetTokenDetails: props<{ tokenDetails: TokenDetails }>(),
    GetUserTenants: props<{ uniqueId: string }>(),
    GetUserTenantsSuccess: props<{ uniqueId: string; tenants: Array<Tenant> }>(),
    GetUserTenantsFailure: props<{ error: HttpErrorResponse }>(),
    GetUserRoles: props<{ uniqueId: string; tenant: string }>(),
    GetUserRolesSuccess: props<{ roles: Array<ProfileRole>; tenant: string }>(),
    GetUserRolesFailure: props<{ error: HttpErrorResponse }>(),
    GetUserFeatures: props<{ role: string; tenant: string }>(),
    GetUserFeaturesSuccess: props<{ features: Array<ProfileFeature> }>(),
    GetUserFeaturesFailure: props<{ error: HttpErrorResponse }>(),
    setActiveRole: props<{ active_role: ProfileRole }>(),
    setActiveTenant: props<{ active_tenant: Tenant }>(),
  },
});
