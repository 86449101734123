import { globalEnvironment } from './global';

export const environment = {
  ...globalEnvironment,
  admin_tool_url: 'https://gw-dev.cube.rina.org/admin-tool',
  api: {
    functions: 'https://gw-dev.cube.rina.org/dias-ghg-func/dev',
    grafana: 'https://grafana-e2e.azurewebsites.net',
  },
  b2cPolicies: {
    tenant: 'rinagroupb2cdevl',
    clientId: 'd39d836e-d387-4fed-b7af-75161b95416e',
    tenantId: 'c7788286-49f4-40db-bf26-fc1c677df787',
    // redirectUri: 'http://localhost:4200', for development
    redirectUri: 'https://ghgprojects-dev.cube.rina.org',
    redirectCourtesyPageUri: 'https://wwww.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
    b2cLoginBaseUrl: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/',
    names: {
      resetPassword: 'B2C_1A_PASSWORDRESET',
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://test-login.rina.org/',
    subdomain: 'rinagroupb2cdevl.onmicrosoft.com',
    authorityDomain: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com',
  },
  base_url: 'https://gw-dev.cube.rina.org/dias-ghg-func/dev',
  localStorageKeyPrefix: 'cube-dias-ghg-dev',
};
