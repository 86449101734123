/* eslint-disable @ngrx/no-multiple-actions-in-effects */
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, delay, map, of, switchMap, tap } from 'rxjs';

import { AuthenticationService } from 'src/app/providers/services/authentication.service';
import { AuthActions } from '../actions/auth.actions';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Tenant, ProfileRole, ProfileFeature } from 'src/app/models/auth/admin-tool.type';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { environment } from 'src/environments/environment';
import { SystemActions } from '../actions/system.actions';
import { selectActiveTenant, selectTokenDetails } from '../selectors/auth.selectors';
import { AppState } from '../states/app.state';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  private readonly authService: AuthenticationService = inject(AuthenticationService);
  private readonly actions$: Actions = inject(Actions);
  private readonly store: Store<AppState> = inject(Store);
  private router = inject(Router);

  redirectToGateway$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.redirectToGateway),
        tap(() => this.authService.login())
      );
    },
    { dispatch: false }
  );

  setActiveAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.setActiveAccount),
        tap(() => this.authService.checkAndSetActiveAccount())
      );
    },
    { dispatch: false }
  );

  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.login),
        tap(() => this.authService.login())

      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.authService.logout())
      );
    },
    { dispatch: false }
  );

  changeActiveTenant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setActiveTenant),
      tap(({ active_tenant }) => localStorage.setItem(`${environment.localStorageKeyPrefix}_tenant`, active_tenant.name)),
      concatLatestFrom(() => this.store.select(selectTokenDetails)),
      map(([{ active_tenant }, tokenDetails]) => AuthActions.getUserRoles({ uniqueId: tokenDetails?.uniqueId ?? '', tenant: active_tenant.name }))
    );
  });

  changeActiveRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setActiveRole),
      concatLatestFrom(() => this.store.select(selectActiveTenant)),
      map(([{ active_role }, tenant]) => AuthActions.getUserFeatures({ role: active_role.name, tenant }))
    );
  });

  userTenants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserTenants),
      switchMap(({ uniqueId }) =>
        this.authService.tenants(uniqueId).pipe(
          switchMap((tenants: Array<Tenant>) => {
            if (tenants.length) {
              const current_tenant: string | null = localStorage.getItem(`${environment.localStorageKeyPrefix}_tenant`);
              const active_tenant: Tenant = current_tenant ? { name: current_tenant, info: '' } : tenants[0];
              return [AuthActions.getUserTenantsSuccess({ uniqueId, tenants }), AuthActions.setActiveTenant({ active_tenant })];
            } else {
              return [AuthActions.setTokenDetails({ tokenDetails: <TokenDetails>{} }), SystemActions.redirectToCourtesyPage()];
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.getUserTenantsFailure({ error })))
        )
      )
    );
  });

  userTenantsFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.getUserTenantsFailure, AuthActions.getUserRolesFailure),
        tap(({ error }) => {
          if (error.status === HttpStatusCode.NotFound) {
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this.store.dispatch(SystemActions.redirectToCourtesyPage());
          }
        })
      );
    },
    { dispatch: false }
  );

  userRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserRoles),
      delay(200),
      switchMap(({ uniqueId, tenant }) =>
        this.authService.roles(uniqueId, tenant).pipe(
          map((roles: Array<ProfileRole>) => {
            if (roles.length) {
              if (roles[0].name === 'customer') {
                this.router.navigateByUrl('home/customer-transactions/carbon-credit/carbon-credits-list');
              } else {
                this.router.navigateByUrl('home/auditor-transactions/carbon-credit/carbon-credits-list');
              }
              return AuthActions.getUserRolesSuccess({ roles, tenant });
            } else {
              return SystemActions.redirectToCourtesyPage();
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.getUserRolesFailure({ error })))
        )
      )
    );
  });

  userRolesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserRolesSuccess),
      map(({ roles, tenant }) => AuthActions.getUserFeatures({ role: roles.at(0)?.name ?? '', tenant }))
    );
  });

  userFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserFeatures),
      switchMap(({ role, tenant }) =>
        this.authService.features(role, tenant).pipe(
          map((features: Array<ProfileFeature>) => {
            if (features.length) {
              return AuthActions.getUserFeaturesSuccess({ features });
            } else {
              return SystemActions.redirectToCourtesyPage();
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.getUserFeaturesFailure({ error })))
        )
      )
    );
  });
}
