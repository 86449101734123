/* eslint-disable @ngrx/no-typed-global-store */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { AppState } from 'src/app/store/states/app.state';
import { Store } from '@ngrx/store';

@Injectable()
export class AzureTokenInterceptor implements HttpInterceptor {
  readonly authService: AuthenticationService = inject(AuthenticationService);
  readonly #store: Store<AppState> = inject(Store<AppState>);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.token.pipe(
      filter((token: string | undefined): token is string => !!token),
      map((token: string) => ({
        token,
        appendToken: this.authService.resourceNeedsToken(req.url)
      })),
      switchMap(({ token, appendToken }) => {
        const clonedReq =
          token && appendToken
            ? req.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`
                }
              })
            : req;

        return next.handle(clonedReq);
      })
    );
  }
}
